import React from "react";
import ReactDOMClient from "react-dom/client";
import  App  from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  // Remove StrictMode for development to prevent double calls
  <GoogleOAuthProvider clientId="your-client-id-here">
    <App />
  </GoogleOAuthProvider>
);
